import React, { useRef } from "react"
import styled from "styled-components"
import Flip from "react-reveal/Flip"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Section from "../elements/Section"
import Container from "../elements/Container"
import Title, { TitleH2 } from "../elements/Title"
import globalVariables from "../globalVariables"
import { NextArrow, PrevArrow } from "../elements/ArrowCarousel"

const WrapperSlider = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    width: 250px;
    margin: auto;
    margin-top: 3rem;
  }
  margin-top: 60px;
`
const Item = styled.div`
  text-align: center;
  img {
    margin: 0 auto 30px;
    width: 90px;
  }

  @media (max-width: ${globalVariables.maxMobile}) {
    .react-reveal {
      animation: none !important;
      opacity: 1 !important;
    }
  }
`

const Text = styled.p`
  padding: 5px 0 20px;
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 10px 0 25px;
  }
`

const Valeurs = ({ title, items }) => {
  const sliderRef = useRef()

  const settings = {
    dots: false,
    speed: 1000,
    slidesToShow: items.length,
    slidesToScroll: 0,
    initialSlide: 0,
    nextArrow: <NextArrow color />,
    prevArrow: <PrevArrow color />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          autoplay: true,
          infinite: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          afterChange: slick => {
            if (slick === items.length - 1) {
              setTimeout(function() {
                sliderRef.current.slickGoTo(0)
              }, 2000)
            }
          },
        },
      },
    ],
  }

  return (
    <Section id="valeurs">
      <Container>
        <TitleH2 className="title1" position="center">
          {title}
        </TitleH2>
        <WrapperSlider>
          <Slider ref={sliderRef} {...settings}>
            {items.map((item, index) => (
              <Item key={index}>
                <Flip left delay={index * 300}>
                  <img
                    src={
                      item.valeur.image_valeur.localFile.childImageSharp.fluid
                        .src
                    }
                    alt={item.valeur.titre_valeur}
                  />
                </Flip>
                <Title className="title3" margin="0">
                  {item.valeur.titre_valeur}
                </Title>
                <Text>{item.valeur.texte_valeur}</Text>
              </Item>
            ))}
          </Slider>
        </WrapperSlider>
      </Container>
    </Section>
  )
}

export default Valeurs
