import React from "react"
import styled from "styled-components"
import Bounce from "react-reveal/Bounce"

import Img from "gatsby-image"

import Section from "../elements/Section"
import globalVariables from "../globalVariables"

const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &.chiffres > div:nth-child(odd) {
    background-color: ${props => props.theme.primary};
  }
  &.chiffres > div:nth-child(even) {
    background-color: ${props => props.theme.secondary};
  }
  &.chiffres > div {
    padding: 2rem;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    flex-wrap: wrap;
    &.chiffres > div {
      padding: 4rem 1rem;
      min-height: 290px;
      flex: 1 0 50%;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    &.chiffres > div {
      flex: 1 0 33.33%;
    }
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 1 100%;
  border-bottom: 1px solid white;
  .gatsby-image-wrapper {
    width: 100%;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: ${props => (props.flexMedium ? props.flexMedium : "1 1 100%")};
    border-bottom: none;
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    flex: ${props => props.flex};
  }
`

const Wrapper = styled.div`
  text-align: center;
`

const Number = styled.p`
  font-size: 100px;
  font-weight: bold;
  color: white;
  margin-bottom: 0;
`

const Text = styled.p`
  color: white;
  margin-bottom: 0;
`

const AgenceChiffres = ({ image, items }) => {
  return (
    <Section padding="0" border>
      <Row>
        <Item flex="0 0 60%" flexMedium="0 0 70%">
          <Row className="chiffres">
            {items.map((item, index) => (
              <Item key={index}>
                <Bounce left delay={index * 100}>
                  <Wrapper>
                    <Number>{item.bloc_chiffre.chiffre}</Number>
                    <Text>{item.bloc_chiffre.description}</Text>
                  </Wrapper>
                </Bounce>
              </Item>
            ))}
          </Row>
        </Item>
        <Item flex="0 0 40%" flexMedium="0 0 30%">
          <Img fluid={image} />
        </Item>
      </Row>
    </Section>
  )
}

export default AgenceChiffres
