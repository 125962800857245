import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AgenceBlocs from "../components/Agence"
import AgenceChiffres from "../components/Agence/chiffres"
import Certifications from "../components/Home/Certifications"
import Valeurs from "../components/Metiers/valeurs"
import Contact from "../components/Home/Contact"
import Join from "../components/Agence/join"
import Faq from "../components/elements/Faq"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const anchors = [
  {
    content: "Notre vision",
    anchor: "agence-bloc-1",
  },
  {
    content: "Notre équipe",
    anchor: "equipe",
  },
  {
    content: "Nos valeurs",
    anchor: "valeurs",
  },
]

const bannerLeftCardContent = {
  mainContent: `Depuis 2011, SLAP digital s'appuie sur une équipe d'experts pour vous proposer des
    accompagnements adaptés à vos enjeux et objectifs dans le but d'accroitre les performances
    de votre stratégie digitale : Acquisition, Expérience Utilisateur et Data Marketing.`,
  titleFirstLine: "L'agence",
  titleSecondLine: "SLAP digital",
  subTitle: "Qui sommes-nous ?",
}

const Agence = ({ data, location }) => {
  const {
    agence,
    articles,
    bloc_valeurs,
    bloc_certifications,
    bloc_contact,
  } = data

  return (
    <Layout
      location={location.pathname}
      crumbLabel="L'agence"
      locationGTM={location.href}
    >
      <SEO
        title={agence.acf.title}
        description={agence.acf.meta_description}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        anchors={anchors}
        hasBreadCrumb
        hasContactButton
        hasSlider
        isAgencePage
        bannerLeftCardContent={bannerLeftCardContent}
        articles={articles.edges.slice(Math.max(articles.edges.length - 3, 0))}
      />
      <AgenceBlocs
        image={agence.acf.image_1.localFile.childImageSharp.fluid}
        title={agence.acf.titre_1}
        text={agence.acf.texte_1}
        flexImage="1 0 50%"
        flexText="1 0 50%"
        backgroundText="primary"
        colorText="white"
        orderImage="1"
        ordertext="2"
        orderImageMobile="2"
        ordertextMobile="1"
      />
      <AgenceChiffres
        image={agence.acf.image_chiffre.localFile.childImageSharp.fluid}
        items={agence.acf.chiffres}
      />
      <AgenceBlocs
        image={agence.acf.image_equipe.localFile.childImageSharp.fluid}
        title={agence.acf.titre_equipe}
        text={agence.acf.texte_equipe}
        flexImage="1 0 69%"
        flexText="1 0 31%"
        backgroundText="greyLighter"
        orderImage="2"
        ordertext="1"
        orderImageMobile="2"
        ordertextMobile="1"
        isTeamBloc
        id="equipe"
      />
      <Valeurs
        title={bloc_valeurs.acf.titre_valeurs}
        items={bloc_valeurs.acf.valeurs}
      />
      <Certifications
        title={bloc_certifications.acf.titre_certification}
        text={bloc_certifications.acf.texte_certification}
        items={bloc_certifications.acf.certifications}
      />
      <Join
        title={agence.acf.titre_rejoignez_nous}
        description={agence.acf.texte_rejoignez_nous}
        imageBanner={
          agence.acf.image_rejoignez_nous.localFile.childImageSharp.fluid
        }
        imageHeight="430px"
        imageMobileHeight="426px"
      />
      <Faq
        title={agence.acf.titre_faq}
        text={agence.acf.texte_faq}
        themes={agence.acf.themes_faq}
      />
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default Agence

export const query = graphql`
  query {
    agence: wordpressPage(id: { eq: "07ab9123-ee9b-57ac-808b-aa388fa1bdfe" }) {
      acf {
        titre_banniere
        texte_banniere
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        hashtag
        title
        meta_description
        titre_1
        texte_1
        image_1 {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        chiffres {
          bloc_chiffre {
            chiffre
            description
          }
        }
        image_chiffre {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_equipe
        texte_equipe
        image_equipe {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_rejoignez_nous
        texte_rejoignez_nous
        image_rejoignez_nous {
          localFile {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        titre_faq
        texte_faq
        themes_faq {
          titre_theme_faq
          questions_faq {
            question_faq
            reponse_faq
            lien_faq
            texte_bouton_faq
          }
        }
      }
    }
    bloc_valeurs: wordpressAcfBlocs(
      id: { eq: "260faed8-6243-5382-90f5-6012aaa140c8" }
    ) {
      acf {
        valeurs {
          valeur {
            texte_valeur
            titre_valeur
            image_valeur {
              localFile {
                childImageSharp {
                  fluid(quality: 100) {
                    src
                  }
                }
              }
            }
          }
        }
        titre_valeurs
      }
    }

    bloc_certifications: wordpressAcfBlocs(
      id: { eq: "b093d1ee-95cf-54db-b63c-5f4803c934fb" }
    ) {
      acf {
        titre_certification
        texte_certification
        certifications {
          logo_certification {
            alt_text
            localFile {
              childImageSharp {
                fluid(quality: 100) {
                  src
                }
              }
            }
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
    articles: allWordpressPost(
      limit: 3
      sort: { order: DESC, fields: modified }
    ) {
      edges {
        node {
          path
          acf {
            type_article
            theme_article
            niveau_article
            titre_article
            intro_article
            date_de_publication
            vignette_article {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            temps_de_lecture
          }
        }
      }
    }
  }
`
